.care-hero-section {
    position: relative;
    height: 70vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.care-hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}

.care-hero-content {
    position: relative;
    text-align: center;
    z-index: 1; 
    width: 45%;
    max-width: 800px;
}

.care-hero-content h1 {
    font-size: 3.25rem;
    font-weight: bold;
    margin: 0;
    color: white;
    font-family: 'Urbanist', sans-serif;
}

.care-hero-content p {
    font-size: 1.5rem;
    margin-top: 0.8rem;
    color: #f1f1f1;
    font-family: 'Urbanist', sans-serif;
}

.care-search-section {
    margin-top: 5rem;
}

.care-search-title {
    font-size: 1.25rem !important;
    font-weight: 600;
    color: #f1f1f1;
    margin-bottom: 0.5rem;
    font-family: 'Urbanist', sans-serif;
}

.care-search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.care-search-input {
    padding: 10px 20px;
    font-size: 1.3rem !important;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    outline: none;
    width: 400px;
    height: 60px;
    font-family: 'Urbanist', sans-serif;
}

.care-search-button {
    background-color: #85BB40;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.2rem;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: 5px;
}

.care-search-button i {
    font-size: 1.5rem;
}

.care-search-button:hover {
    opacity: 0.9;
}

.care-chevron-down {
    position: absolute;
    bottom: 20px; 
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 1.25rem;
    cursor: pointer;
    animation: care-bounce 1.5s infinite;
}

.care-no-bounce {
    animation: none; 
}

@keyframes care-bounce {
    0%, 100% {
        transform: translate(-50%, 0);
    }
    50% {
        transform: translate(-50%, 10px);
    }
}

.service-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.service-paragraph {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 1200px;
    margin: 20px auto; 
    gap: 20px;
}

.paragraph-text {
    flex: 0 0 50%; 
    padding: 10px;
    text-align: left;
}

.service-image-container {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-image {
    width: 100%;
    max-width: 100%; 
    height: auto;
}

.discover-services {
    display: flex;
    align-items: center; 
    justify-content: center; 
    gap: 15px;
    margin: 0 auto; 
    width: 100%; 
    max-width: 900px; 
    padding: 40px 0px 0px 0px;
}

.discover-services .line {
    flex-grow: 1;
    height: 1.3px; 
    background-color: #85BB40;
}

.discover-services .discover-text {
    font-size: 17px;
    font-weight: 600;
    color: #85BB40;
    font-family: 'Urbanist', sans-serif; 
    text-transform: uppercase; 
    letter-spacing: 1px; 
    white-space: nowrap; 
    text-align: center; 
}

.service-sections {
    background-color: #f9fafc;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; 
    width: 100%;
    padding: 20px 0; 
}

.section-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80vw;
    max-width: 1200px;
    gap: 20px;
    flex-wrap: wrap;
}

.text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    text-align: left;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.section-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.green-section {
    background-color: #85BB40;
    color: white;
    padding: 40px 0;
}

.section-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: 'Urbanist', sans-serif;
}

.section-description {
    font-size: 1.1rem;
    margin-bottom: 15px;
    font-family: 'Urbanist', sans-serif;
}

.service-list {
    font-size: 1.1rem;
    line-height: 1.6;
    font-family: 'Urbanist', sans-serif;
    list-style-type: disc;
    padding-left: 20px;
}


@media screen and (max-width: 768px) {
    .care-hero-section {
        height: 100vh;
    }

    .care-hero-content h1 {
        font-size: 2.75rem;
    }
    
    .care-hero-content p {
        font-size: 1.2rem;
        margin-top: 0.8rem;
    }

    .care-hero-content {
        width: 90%;
    }

    .service-title {
        font-size: 1.5rem;
    }

    .discover-services .discover-text {
        font-size: 14px;
    }

    .section {
        height: auto;
    }

    .section-content {
        flex-direction: column; 
        align-items: center; 
        text-align: center;
        width: 100vw;
    }

    .section-title {
        font-size: 1.5rem; 
        margin-bottom: 20px; 
        text-align: center;
    }

    .image-container {
        order: 2; 
        padding: 0px;
    }

    .text-container {
        order: 1; 
        align-items: center;
        
    }

    .service-list {
        font-size: 1rem; 
        line-height: 1.5;
        margin-left: 0;
        list-style-position: inside; 
    }

    .section.green-section,
    .section.white-section {
        padding: 20px;
    }

    .service-paragraph {
        flex-direction: column; 
        width: 100%;
    }

    .service-image-container {
        order: 1;
        margin-bottom: 20px;
    }

    .paragraph-text {
        order: 2;
        text-align: center;
    }
}