.service-description {
    position: relative;
    padding: 60px 20px;
    text-align: center;
    color: #174374;
    background-color: #f9fafc;
    overflow: hidden;
    align-content: center;
    justify-content: center;
}

.service-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #174374;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 1rem;
}

.service-subtitle {
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto 3rem auto;
    color: #174374;
    font-family: 'Urbanist', sans-serif;
}

.service-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default: 3 cards per row */
    gap: 16px;
    max-width: 800px;
    margin: 0 auto;
}

.service-card {
    border-radius: 20px; /* Default border radius */
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: scale(1.05);
}

.ellipse-bg {
    position: absolute;
    border-radius: 50%;
    opacity: 0.2;
    filter: blur(100px);
    background-color: #6BDDCA;
    pointer-events: none;
}

.ellipse1 {
    width: 1000px;
    height: 700px;
    top: 200px;
    left: -150px;
}

.ellipse2 {
    width: 1000px;
    height: 700px;
    top: 200px;
    right: -150px;
}

.cta-button {
    background-color: #85BB40;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 15px 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 3.5rem;
    font-family: 'Urbanist', sans-serif;
    display: inline-block;
    text-align: center;
}

.cta-button:hover {
    background-color: #6ca233; 
}

@media screen and (max-width: 768px) {
    .service-title {
        font-size: 1.9rem;
        margin-bottom: 1.5rem;
    }

    .service-subtitle {
        font-size: 1rem;
    }

    .service-cards {
        grid-template-columns: 1fr; 
        gap: 24px; 
        justify-items: center; 
    }

    .service-card {
        border-radius: 20px !important;
    }

    .ellipse-bg {
        display: none;
    }
}
