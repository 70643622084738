.hero-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden; /* Ensure the video fits perfectly */
}

.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire section */
    z-index: -1; /* Place the video behind the content */
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for better text visibility */
    z-index: 0; /* Overlay is above the video but below the content */
}

.hero-content {
    position: relative;
    text-align: center;
    z-index: 1; /* Content is above the overlay and video */
    width: 45%;
    max-width: 800px;
}

.hero-content h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin: 0;
    color: white;
    font-family: 'Urbanist', sans-serif;
}

.hero-content p {
    font-size: 1.75rem;
    margin-top: 0.8rem;
    color: #f1f1f1;
    font-family: 'Urbanist', sans-serif;
}

.search-section {
    margin-top: 5rem;
}

.search-title {
    font-size: 1.25rem !important;
    font-weight: 600;
    color: #f1f1f1;
    margin-bottom: 0.5rem;
    font-family: 'Urbanist', sans-serif;
}

.search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.search-input {
    padding: 10px 20px;
    font-size: 1.3rem !important;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    outline: none;
    width: 400px;
    height: 75px;
    font-family: 'Urbanist', sans-serif;
}

.search-button {
    background-color: #85BB40;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.2rem;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: 5px;
}

.search-button i {
    font-size: 1.5rem;
}

.search-button:hover {
    opacity: 0.9;
}

.chevron-down {
    position: absolute;
    bottom: 20px; 
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 1.25rem;
    cursor: pointer;
    animation: bounce 1.5s infinite;
}

.no-bounce {
    animation: none; 
}

@keyframes bounce {
    0%, 100% {
        transform: translate(-50%, 0);
    }
    50% {
        transform: translate(-50%, 10px);
    }
}

@media screen and (max-width: 768px) {
    .hero-content {
        width: 90%;
    }
    .hero-content h1 {
        font-size: 2.75rem;
    }

    .hero-content p {
        font-size: 1.5rem;
        margin-top: 1rem;
    }
}
