.service-card {
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, border-radius 0.3s ease; 
}

.service-card:hover {
    border-radius: 15px !important;
}

.bottom-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)); 
    pointer-events: none; 
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: flex-end;
    padding: 20px;
    transition: background-color 0.3s ease;
}

.service-card:hover .overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.service-card:hover .card-title {
    transform: translateY(-10px);
}

.service-card:hover .learn-more {
    opacity: 1;
    transform: translateY(0);
}

.card-content {
    position: relative;
}

.card-title {
    font-size: 24px;
    font-weight: 600;
    color: white;
    margin: 0;
    transition: transform 0.3s ease;
}

.learn-more {
    text-align: start;
    font-size: 16px;
    font-weight: 500;
    color: #85BB40;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.learn-more:hover {
    font-weight: 600;
}