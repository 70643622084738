.contact-section {
    background-color: #174374;
    color: white;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

.contact-container {
    max-width: 1400px;
    width: 85vw; 
    display: flex;
    align-items: center;
    gap: 60px;
}

.contact-left {
    flex: 0 0 53%;
    display: flex;
    justify-content: center;
}

.contact-right {
    flex: 0 0 47%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: start;
}

.contact-description {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 15px;
    text-align: start;
}

.partner-logos {
    display: flex;
    gap: 25px;
    margin-top: 30px;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-image {
    width: 175px; 
    height: auto;
}

.registration-text {
    font-size: 12px;
    font-family: 'Urbanist', sans-serif;
    color: white;
    margin-top: 10px;
    text-align: center;
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
    .contact-card {
        border-radius: 0px;
    }
    .contact-section {
        flex-direction: column;
        height: auto; /* Allow height to adjust based on content */
        padding: 40px 0;
    }

    .contact-container {
        flex-direction: column;
        align-items: center;
        gap: 40px; /* Adjust spacing between sections */
        width: 100%; /* Full width on mobile */
    }

    .contact-left {
        flex: 0 0 auto; /* Let it resize automatically */
        width: 100%; /* Full width on mobile */
        margin: 0; /* Remove extra margin */
        order: 2;
    }

    .contact-right {
        flex: 0 0 auto; /* Let it resize automatically */
        width: 100%; /* Full width on mobile */
        padding: 0 20px; /* Add some padding for content */
        order: 1;
    }

    .contact-card {
        width: 100vw; /* Make card take the full viewport width */
        border-radius: 0; /* Remove rounded corners */
        height: auto; /* Adjust height to fit content */
        padding: 20px; /* Add padding for spacing */
    }

    .contact-title {
        text-align: center;
        font-size: 32px; /* Smaller title size */
    }

    .contact-description {
        font-size: 16px; /* Smaller description size */
        text-align: center;
    }

    .partner-logos {
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px; /* Adjust spacing between logos */
        margin-top: 20px; /* Reduced top margin */
    }

    .logo-container {
        width: 120px; /* Smaller size for logos */
    }

    .logo-image {
        width: 100px; /* Reduce logo size on mobile */
    }
}
