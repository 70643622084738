.video-section {
    background-color: #174374;
    color: white;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.video-container {
    max-width: 1400px;
    width: 75vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-left {
    flex: 0 0 70%;
    display: flex;
    justify-content: center;
}

.video-card {
    background-color: white;
    border-radius: 15px;
    padding: 50px;
    max-width: 700px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.video-title {
    font-size: 32px;
    font-weight: bold;
    color: #174374;
    text-align: center;
    font-family: 'Urbanist', sans-serif;
}

.video-frame-container {
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: 15px;
}

.embedded-video {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 15px;
}

.video-description {
    font-size: 18px;
    line-height: 1.6;
    color: #174374;
    text-align: center;
    font-family: 'Urbanist', sans-serif;
    margin-top: 15px;
}

.button-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.button-filled {
    background-color: #85BB40;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Urbanist', sans-serif;
}

.button-filled:hover {
    background-color: #76a436;
}

.button-outline {
    background-color: transparent;
    color: #85BB40;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    border: 2px solid #85BB40;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: 'Urbanist', sans-serif;
}

.button-outline:hover {
    background-color: #85BB40;
    color: white;
}

.video-right {
    flex: 0 0 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-image.larger {
    width: 200px;
    height: auto;
}

.registration-text {
    font-size: 12px;
    font-family: 'Urbanist', sans-serif;
    color: white;
    margin-top: 10px;
    text-align: center;
}

/* Mobile-specific adjustments */
@media screen and (max-width: 768px) {
    .video-section {
        padding: 60px 0px;
    }

    .video-container {
        flex-direction: column; /* Stack the content vertically */
        width: 100%; /* Full width for the container */
    }

    .video-left {
        flex: 0 0 auto; /* Adjust flex behavior */
        width: 100%; /* Full width for the video */
        margin-bottom: 30px; /* Add spacing between video and logos */
    }

    .video-card {
        border-radius: 0; /* Remove rounded corners */
        padding: 30px 20px; /* Reduce padding for smaller screens */
        box-shadow: none; /* Remove shadow for a simpler design */
    }

    .video-title {
        font-size: 24px; /* Adjust font size for smaller screens */
    }

    .video-description {
        font-size: 16px; /* Adjust description font size */
    }

    .button-filled,
    .button-outline {
        font-size: 16px; /* Adjust button font size */
        padding: 10px 20px; /* Adjust button padding */
    }

    .video-right {
        flex: 0 0 auto; /* Adjust flex behavior */
        flex-direction: row;
        width: 100%; /* Full width for logos section */
        gap: 15px; /* Reduce spacing between logos */
        align-items: center; /* Center the logos */
        justify-content: center;
    }

    .logo-container {
        flex-direction: column;
        align-items: center;
    }

    .logo-image.larger {
        width: 120px; /* Smaller logos for mobile */
    }

    .registration-text {
        font-size: 10px; /* Smaller text for registration note */
    }
}